import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import Image1 from "images/howitworksdonate.png";
import Image2 from "images/howitworkstickets.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto md:py-10 md:py-12`;
const TwoColumn2 = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto md:pb-10 md:pb-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const BlueLink = tw.a`text-blue-brightest hocus:text-blue-600`
const UnorderList = tw.ul`text-secondary-200 pt-2`
const ListItem = tw.li`align-top text-lg text-secondary-100 font-medium`
const BoldSpan = tw.span`font-bold`;
const ItalicSpan = tw.span`italic`;
const BoldISpan = tw.span`font-bold italic`;
const ImageColumn = tw(Column)`w-full md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`w-full px-4 md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const ImageTicketDiv = tw.div`md:py-5`;
const ImageTicket = tw.img`rounded bg-cover bg-center`;
const ImageTicket2 = tw.img`rounded bg-cover bg-center md:pr-32 md:pt-40 md:max-w-2xl`;

const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-300 mt-4`

const AboutUs = ({textOnLeft = false}) => {

  return (
    <div id="nav-about-us">
    <Container>
      <TwoColumn>
        <ImageColumn>
        <ImageTicketDiv>
          <ImageTicket src={Image1} title="Image by Kriss Nasri" />
        </ImageTicketDiv>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>How it works - Donate</Heading>
            <Description><BoldSpan>Our anonymous pay-it-forward ticket system in action:</BoldSpan></Description>
            <Description><BoldSpan>Donations:</BoldSpan></Description>
            <Description>If you have the means to help out a fellow comedy fan you can <BlueLink href="/donate">donate here</BlueLink>.</Description>
            <Description>We welcome ANY amount of donation, no matter how big or small. It can be the price of a ticket or pass for a specific event/show or just whatever you can afford.</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
    <Container>
      <TwoColumn2>
      <ImageTicketDiv>
        <ImageTicket2 src={Image2} title="Image by Kriss Nasri" />
      </ImageTicketDiv>
        <TextColumn textOnLeft={!textOnLeft}>
          <TextContent>
            <Heading>How it works - Tickets</Heading>
            <Description><BoldSpan>Requests:</BoldSpan></Description>
            <Description>If you are a comedy fan who wants to see a show online but you're struggling financially, simply <BlueLink href="/request">click here</BlueLink> and fill in your details.</Description>
            <Description><BoldSpan>Essential Information:</BoldSpan></Description>
            <UnorderList>
              <ListItem>We don’t ask <BoldISpan>any</BoldISpan> financial background/means testing questions.</ListItem>
              <ListItem>We trust the integrity of those requesting a ticket and take it on good faith that nobody here is taking advantage of others’ goodwill.</ListItem>
              <ListItem>Any details we do request are necessary for the purchase of tickets.</ListItem>
              <ListItem>We take the details you provide and use the funds from the donation pot to buy the tickets requested.</ListItem>
              <ListItem>Ticket recipient details are kept entirely confidential (other than where sharing is required in order to purchase the ticket).</ListItem>
              <ListItem>Ticket recipients will be contacted directly by <ItalicSpan>Below The Line</ItalicSpan> via email to confirm the purchase has been successful and provide details of their ticket.</ListItem>
              <ListItem><BoldSpan>Everyone</BoldSpan> gets to enjoy comedy shows together!</ListItem>
            </UnorderList>
            <Description>(Please see our <BlueLink href="/privacy">Privacy Policy</BlueLink> for full details of how we use your data.)</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn2>
    </Container>
    </div>
  );
};

export default AboutUs
