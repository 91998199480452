import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
const Select = tw.select`h-10 text-sm text-blue-brightest font-bold border-0  w-2/3 mt-2 p-1 hocus:border-0 bg-gray-100 rounded `;

const Select10  = (props) => {
  return (
  <Select name={props.name} id={props.name}>
    <option value="">Please select an option...</option>
    <option value="twitter">Twitter</option>
    <option value="instagram">Instagram</option>
    <option value="mark">LCF Twitter/Newletter</option>
    <option value="nextup">NextUp Twitter/Newletter</option>
    <option value="google">Google</option>
    <option value="other">Other</option>
  </Select>
  );
};

export default Select10;
