import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import Image1 from "images/donate1.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);
const FormDiv = tw.div`flex flex-col items-center py-10`;
const BoldSpan = tw.span`font-bold`;
const YellowSpan = tw.span`text-primary-600`

const Image = styled.div(props => [
  `background-image: url("${Image1}");`,
  tw`rounded bg-cover bg-center h-full p-10`,
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-300 mt-4`

const AboutUs = ({textOnLeft = false}) => {

  return (
    <div id="nav-donate">
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={Image1} title="Image by Kriss Nasri" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Donate</Heading>
            <Description>If you are a comedy fan and you are in a position to help fellow fans to enjoy comedy shows: thank you!</Description>
            <Description>This can be as little or as much as you like. It can be a penny, a pound, the price of a specific ticket for an event or <BoldSpan>ANY</BoldSpan> amount you would like to give.</Description>
            <Description>All of your donations will go towards providing <YellowSpan>a helping hand to access comedy</YellowSpan>.</Description>
            <FormDiv>
              <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="KZMR4ESZNQ9HS" />
                <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
              </form>
            </FormDiv>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
    </div>
  );
};

export default AboutUs
