import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import Image1 from "images/nextup_lineup24.jpeg";
import Image2 from "images/nextup_fringe_logo.png";
import  {
  PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";

const Container = tw.div`relative bg-primary-900 text-gray-100`;
const TwoColumn = tw.div`flex flex-col-reverse pb-16 px-4 md:flex-row justify-between max-w-screen-xl mx-auto md:py-20 lg:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const BlueLink = tw.a`text-primary-300 hocus:text-blue-400 `
const YellowSpan = tw.span`text-primary-600`
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const ImageDisplay = tw.img`md:pt-20`
const ImageDisplay2 = tw.img`mt-2 md:mt-20 p-2 rounded-2xl mx-auto bg-primary-900`
const TextContent = tw.div`lg:py-8`;
const FormDiv = tw.div`flex flex-col items-center py-2 md:py-10`;
const ButtonDiv = tw.div`flex justify-center py-6`;
const ItalicSpan = tw.span`italic`;
const SecondaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-500 lg:hocus:bg-primary-600 text-lg text-gray-700 font-bold mr-2`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Heading2 = tw(SectionHeading)`text-left text-xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight pt-4`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-100 mt-4`

const EdFringe = ({textOnLeft = false}) => {

  return (
    <div id="nav-about-us">
    <Container>
      <TwoColumn>
        <ImageColumn>
          <a href="https://nextupcomedy.com/pages/edfringe" target="_default"><ImageDisplay src={Image1} alt="Edinburgh Comedy Festival Logo" /></a>
          <a href="https://nextupcomedy.com/" target="_default"><ImageDisplay2 src={Image2} alt="NextUp Logo" /></a>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Edinburgh Festival Fringe</Heading>
            <Description>Once again we are partnering with the best of online comedy distributors,  <BlueLink href="https://nextupcomedy.com/"> NextUp</BlueLink>! We are here to provide <YellowSpan>a helping hand to access comedy</YellowSpan>.</Description>
            <Description>We will be using our ticket system to facilitate access to the Edinburgh Festival Fringe 2024, and the wonderfully generous and good looking people at NextUp are matching your donations <ItalicSpan>again</ItalicSpan>. That means for every pass your donations pay for, NextUp will pop on a free one for us to give out, making your donation go twice as far.</Description>
            <Heading2>Request a Pass for free!</Heading2>
            <Description>If you already have a NextUp pass you already have access to all the fringe streaming goodies they have to offer for this year's Edinburgh Fringe 2024. If, however, you are sorely missing this pass and can't afford it, and would like to join the party, please click the button below and fill out the form to request a pass/ticket. We don't ask any awkward means-tested questions.</Description>
            <ButtonDiv><SecondaryLink href="/edfringefestival#nav-festival-tickets">Request A Fringe Ticket</SecondaryLink></ButtonDiv>
            <Heading2>Donate</Heading2>
            <Description>In order to help those who cannot afford a pass, we are entirely reliant on <ItalicSpan>your</ItalicSpan> generous donations. So if you are able to donate, and would like to, we'd be super grateful for anything you are willing to give, whether that's the full £35 for a pass or £1.  THANK YOU!</Description>
            <FormDiv>
              <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="hosted_button_id" value="KZMR4ESZNQ9HS" />
                <input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
              </form>
            </FormDiv>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
    </div>
  );
};

export default EdFringe
