import React from "react"
import "style.css"
import "tailwindcss/lib/css/preflight.css"
import Nav from "components/btl/Nav"
import Hero from "components/btl/Landing"
import News from "components/btl/NewsSection"
import Values from "components/btl/OurValues"
import Tickets from "components/btl/TicketSection"
import About from "components/btl/AboutUs"
import Contact from "components/btl/ContactUs"
import FestivalSection from "components/btl/FestivalTicketSection"
import Donate from "components/btl/Donate"
import HowItWorksInfo from "components/btl/HowItWorks"
import FestivalInfo from "components/btl/Festivals"
import EdFringe from "components/btl/EdFringe"
import PrivacyInfo from "components/btl/Privacy"
import Footer from "components/btl/Footer"
import CookieConsent from "react-cookie-consent"

const ConsentButtonText = 'OK'
const ConsentText = 'This website uses cookies to enhance the user experience. '

function App() {
  switch(window.location.pathname ) {
    case '/how':
      return <HowItWorks />
    case '/contact':
      return <ContactSingle />
    case '/request':
      return <RequestSingle />
    case '/donate':
      return <DonateSingle />
    case '/edfringe':
      return <EdinburghFringe />
    case '/about':
      return <AboutSingle />
    case '/values':
      return <ValueSingle />
    case '/festivalticket':
      return <FestivalFormSingle />
    case '/festivals':
      return <Festivals />
    case '/privacy':
      return <PrivacyPolicy />
    default:
      return <Home />
  }
}

function Home() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Hero />
      <News />
      <Values />
      <Tickets />
      <About />
      <Contact />
      <Donate textOnLeft={true}/>
      <Footer />

    </div>
  )
}

function HowItWorks() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <HowItWorksInfo />
      <Footer />

    </div>
  )
}

function Festivals() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <FestivalInfo />
      <Footer />

    </div>
  )
}

// function EdFringeFestival() {
//   return (
//     <div>
//       <CookieConsent
//         location="bottom"
//         buttonText={ConsentButtonText}
//         cookieName="myAwesomeCookieName2"
//         style={{ background: "#2B373B" }}
//         buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
//         expires={150}
//       >
//         {ConsentText}
//       </CookieConsent>
//       <Nav />
//       <FestivalSection />
//       <Footer />
//
//     </div>
//   )
// }

function PrivacyPolicy() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <PrivacyInfo />
      <Footer />

    </div>
  )
}

function ContactSingle() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <Contact />
      <Footer />

    </div>
  )
}

function RequestSingle() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <Tickets />
      <Footer />

    </div>
  )
}

function DonateSingle() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <Donate textOnLeft={true} />
      <Footer />

    </div>
  )
}

function AboutSingle() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <About showSpecialSection={true} />
      <Footer />

    </div>
  )
}

function ValueSingle() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <Values />
      <Footer />

    </div>
  )
}

function FestivalFormSingle() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <FestivalSection />
      <Footer />

    </div>
  )
}

function EdinburghFringe() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <EdFringe textOnLeft={true} />
      <Footer />

    </div>
  )
}

export default App
