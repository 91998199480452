import React, { useRef } from 'react';
import tw from "twin.macro";
import emailjs from '@emailjs/browser';
import { ToastContainer as ToastContainerRequest, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectHear from 'components/btl/form-elements/SelectHear'
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-1/2 px-5 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide text-gray-700 font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-700 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hover:-translate-y-px hover:shadow-xl focus:-translate-y-px focus:shadow-xl`;

 const ContactUs = () => {
  const ticketform = useRef();

  const sendTicketEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1paqs9l', 'template_7e40rtr', ticketform.current, 'WFlDbfM3E5_EUtkv-')
      .then((result) => {
        toast("Thank you so much for your email, we will be in touch!", { type: 'success', theme: 'colored'});
          console.log(result.text);
      }, (error) => {
        toast("Error: Please try again!", { type: 'error', theme: 'colored'});
          console.log(error.text);
      });
  };

  const urlParams = new URLSearchParams(window.location.search)
  const choice = urlParams.get('choice')
  let gigText = ''
  if (choice === 'NYE') {
    gigText = 'JOHN’S SO-LOW NEW YEAR’S EVE EVE: 30/12 8PM'
  }

  return (
    <form ref={ticketform} onSubmit={sendTicketEmail}>
      <ToastContainerRequest />
      <TwoColumn>
        <Column>
          <InputContainer>
            <Label htmlFor="name-input">Your Name</Label>
            <Input id="name-input" type="text" name="name-input" placeholder="John Doe" />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="email-input">Your Email Address</Label>
            <Input id="email-input" type="email" name="email-input" placeholder="john@mail.com" />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="gig-input">What event are you interested in?</Label>
            <Input id="gig-input" type="text" name="gig-input" placeholder="Next Up: Mark Watson WIP" defaultValue={gigText} />
          </InputContainer>
        </Column>
        <Column>
        <InputContainer>
          <Label htmlFor="survey-question-3-input">How did you hear about us?</Label>
          <SelectHear name="survey-question-3-input" />
        </InputContainer>
          <InputContainer tw="flex-1">
            <Label htmlFor="name-input">Your Message</Label>
            <TextArea id="message-input" name="message-input" placeholder="Send us a message"/>
          </InputContainer>
        </Column>
      </TwoColumn>

      <SubmitButton type="submit" value="Submit">Submit</SubmitButton>
    </form>
  );
};

export default ContactUs
