import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";
import { Content2Xl } from "components/misc/Layouts.js";
import logoImageSrc from "images/BTL_Olana_Logo.png";
import serverIllustrationImageSrc from "images/server-illustration-2.svg";

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-4 py-8 -mx-4 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hover:text-primary-300 lg:hover:border-gray-100 lg:focus:text-primary-300 lg:focus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-primary-300`;
const SecondaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-500 lg:hocus:bg-primary-600 text-gray-700 font-bold mr-2`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-500 lg:hocus:bg-primary-600 text-gray-700 font-bold`;

const TwoColPrimaryBkround  = ({
  heading = "Below The Line",
  description = "Nice little tag line",
  secondaryButtonText = "Request a Ticket",
  primaryButtonText = "Make a Donation",
  secondaryButtonUrl = "/request",
  primaryButtonUrl = "/donate",
  imageSrc = serverIllustrationImageSrc,
}) => {
  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" />
      Below The Line
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/how">How It Works</NavLink>
      <NavLink href="/values">Our Values</NavLink>
      <NavLink href="/festivals">Festivals</NavLink>
      <NavLink href="/about">About Us</NavLink>
      <NavLink href="/contact">Contact Us</NavLink>
      <SecondaryLink href="/request">Request</SecondaryLink>
      <PrimaryLink href="/donate">Donate</PrimaryLink>
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};

export default TwoColPrimaryBkround;
