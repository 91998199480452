import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/BTL_Olana_Logo.png";
import { ReactComponent as DiscordIcon } from "../../images/discord-v2-svgrepo-com.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram-svgrepo-com.svg";

const Container = tw(ContainerBase)`bg-primary-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-blue-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const logoTitle = "Logo Design Winner: Olana Pursaill 2022"

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
const MiniFooter = () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} title={logoTitle}/>
            <LogoText>Below The Line</LogoText>
          </LogoContainer>
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/how">How It Works</Link>
            <Link href="/values">Our Values</Link>
            <Link href="/festivals">Festivals</Link>
            <Link href="/about">About Us</Link>
            <Link href="/contact">Contact Us</Link>
            <Link href="/privacy">Privacy Policy</Link>
            <Link href="/donate">Donate</Link>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink title="Join our discord server" href="https://discord.gg/t4hzfA3cmD" target="_default">
              <DiscordIcon />
            </SocialLink>
            <SocialLink title="Twitter" href="https://twitter.com/AccessComedyBTL" target="_default">
              <TwitterIcon />
            </SocialLink>
            <SocialLink title="Instagram" href="https://www.instagram.com/accesscomedybtl/" target="_default">
              <InstagramIcon />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2022, Below The Line. Registered Charity No: SC052405. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};

export default MiniFooter;
