import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import Image1 from "images/whoarewe1.jpg";
import Image2 from "images/whatwedotickets2.png";
import Image3 from "images/whatwedoaccess1.jpg";
import Image4 from "images/whatwedocommunity1.jpg";
import Image5 from "images/whyarewedoingthis1.jpg";

const Container = tw.div`relative`;
const ContainerSpecial = tw.div`relative p-4`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto md:py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ItalicSpan = tw.span`italic`;
const BlueLink = tw.a`text-blue-brightest hocus:text-blue-600`
const YellowSpan = tw.span`text-primary-600`
const BoldSpan = tw.span`font-bold`;
const UnorderList = tw.ul`pt-2 text-secondary-200`
const ListItem = tw.li`align-top text-lg text-secondary-100 font-medium`
const ImaginaryFriend = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-semibold leading-relaxed text-secondary-300 mt-4 italic`
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const ImageColumn2 = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:h-auto pt-4`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);
const TextColumn2 = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  !props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);

const ImageTicketDiv = tw.div`md:py-48`;
const ImageTicket = tw.img`rounded bg-cover bg-center`;
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-300 mt-4`

const AboutUs = ({textOnLeft = false, showSpecialSection = false}) => {
  return (
    <div id="nav-about-us">
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={Image1} title="Photo by Flo Harper" />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Who We Are</Heading>
            <Description>Oh hi there! Welcome <ItalicSpan>Below The Line</ItalicSpan>. Thanks so much for stopping by! If you’ve got a couple of minutes, we would love to share a little about ourselves with you.</Description>
            <ImaginaryFriend>Hey! I do have a couple of minutes actually! So, who ARE you?</ImaginaryFriend>
            <Description>We are a group of like-minded comedy fans and, your friend and mine, friendly neighbourhood comedian, Mark Watson. Together, we decided to formalise this pay it forward scheme into a fully-fledged charity to spread the joy of comedy and make it more accessible for all by providing <YellowSpan>a helping hand to access comedy</YellowSpan>.</Description>
            <Description><BoldSpan>This charity is run on an entirely voluntary basis; no one is paid.</BoldSpan> We are just a group of passionate people trying to bring the comedy community together to do good things. All of your HUGELY appreciated donations will be used to pay for comedy tickets for fellow fans who cannot afford them and to further our objectives. (more details on these things below... <ItalicSpan>*eyes emoji*</ItalicSpan>).</Description>
            <ImaginaryFriend>Oh sorry, speaking of ‘below’, I was actually wondering: why are you called ‘Below The Line’?</ImaginaryFriend>
            <Description>Fair. This does have a somewhat niche origin. We met and became friends online via the fan community surrounding No More Jockeys (cult YouTube show by Mark Watson, Alex Horne & Tim Key). Watson and co. often refer to their viewers as being ‘below the line’ in reference to the line separating a YouTube video from the comments section, where we dwell.</Description>
            <Description>In the way that people often do, we embraced this affectionate nickname and decided to name our online community after it. We feel it’s a good fit as it isn't just YouTube; we, the audience, are <ItalicSpan>below the line</ItalicSpan> of all live-streamed comedy shows- in chat boxes and comment sections, and now one big online community!</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
    <Container>
      <TwoColumn>
        <ImageColumn2>
          <ImageTicketDiv>
            <ImageTicket src={Image2} title="Image by Kriss Nasri" />
          </ImageTicketDiv>
        </ImageColumn2>
        <TextColumn2 textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>What We Do - Tickets</Heading>
            <ImaginaryFriend>OK cool, so I guess I’m Below The Line! You mentioned your aims a minute ago, before I got totally side-tracked by your name, but what do you actually do?</ImaginaryFriend>
            <Description>Well, we’re glad you ask! We are a registered charity and as you will see from <a href="/values">Our Values</a>, it is our mission to make comedy more accessible and inclusive for everyone in the comedy community. </Description>
            <Description>Currently, our main venture is an anonymous pay-it-forward style system for comedy fans to buy online comedy tickets for fellow fans who can’t afford them.</Description>
            <Description>This idea was born in early 2021 when some kind-hearted No More Jockeys fans on our Discord offered to buy livestream tickets for fellow community members who were unable to due to various financial struggles.</Description>
            <Description>Since initially implementing this system, we have consistently found that the number of ticket pledges far outnumbers the amount of requests we receive. And, often, those who receive a ticket pledged by a stranger tell us that they plan to pay forward this kindness to someone else in a different way, or they come back later and pledge a ticket themselves when their financial situation changes.</Description>
            <Description>We believe financial circumstances should not be a barrier to being able to enjoy comedy. Perhaps you need to request a ticket <ItalicSpan>this</ItalicSpan> time because times are tough, but perhaps you can afford to donate the value of a ticket for someone else next time. Or maybe you pass on the kindness in some other way; we understand that paying it forward is <BoldSpan>not</BoldSpan> always financial. It’s up to <BoldSpan>you</BoldSpan>.</Description>
            <Description>The generosity and community spirit of comedy fans is a real force for good! So we've harnessed that force and evolved it into the gameplan you see today. It’s a simple concept which relies on the goodwill and honesty of the comedy fan community, both of which we have found it possesses in absolute SPADES.</Description>
            <Description>Click <BlueLink href="/how">here</BlueLink> to find out how it all works.</Description>
          </TextContent>
        </TextColumn2>
      </TwoColumn>
    </Container>
    {showSpecialSection === true ? <ContainerSpecial>
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={Image3} title="Photo by Flo Harper" />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
            <Heading>What We Do - Access</Heading>
            <ImaginaryFriend>So, will you be doing anything else apart from the online ticket fund?</ImaginaryFriend>
            <Description>Absolutely. We know access to comedy doesn't stop with breaking down financial barriers; we also want to advocate for improved accessibility and inclusivity in comedy (both online and in 'real life') in whatever ways we can.</Description>
            <Description>Any funding we gratefully receive from you, which is not spent on ticket requests, will go directly into this work. This may be in the form of supporting other comedy/access related charities or directed funding for specific projects/causes such as, but not limited to:</Description>
            <UnorderList>
              <ListItem>Putting funds into live-streaming across a large variety of club and theatre shows, so that it becomes the norm for shows to be offered online as well as 'in person'.</ListItem>
              <ListItem>Helping venues to make themselves easier for everyone to access and enjoy.</ListItem>
              <ListItem>Setting up future festivals, shows, events of all kinds with online access at their core rather than as an afterthought.</ListItem>
            </UnorderList>
            <Description>We will of course keep you informed every step of the way and we'd love to create opportunities for the community to vote on causes that are important to you that may need our help.</Description>
            <Description>We really are in our infancy so this is very much a ‘watch this space’ situation. We’ll say it again: *EYES EMOJI*.</Description>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
      <Container>
        <TwoColumn>
          <ImageColumn2>
            <Image imageSrc={Image4} title="Photo by Flo Harper" />
          </ImageColumn2>
          <TextColumn2 textOnLeft={textOnLeft}>
            <TextContent>
              <Heading>What We Do - Community</Heading>
              <ImaginaryFriend>Consider me eyes emoji'd to the max.<br/>You know, you mention 'community' A LOT, can you elaborate a bit more on that too?</ImaginaryFriend>
            <Description>Community is so important to us. Our community started life on Discord as a place for No More Jockeys fans but it has grown (and we hope will continue to grow), into a place for all comedy fans.</Description>
            <Description>Our members are from many different walks of life and from all over the globe so we already have a rich, diverse community who are always supporting or speaking up for each other, or even just sharing their experiences. With your help, we can build on this foundation and take <ItalicSpan>Below The Line</ItalicSpan> into the future with even more connection and community.</Description>
            <Description>We also aim to make this website a hub for the community too. Some of our plans going forward include:</Description>
            <UnorderList>
              <ListItem>Maintaining an online platform which connects like-minded comedy fans; a place where everyone can feel safe, happy and included.</ListItem>
              <ListItem>Hosting a comprehensive directory of online comedy providers such as NextUp and Always Be Comedy.</ListItem>
              <ListItem>Providing a friendly, welcoming space for the display of fan-art.</ListItem>
              <ListItem>Providing a marketplace for fan-made merchandise shops.</ListItem>
            </UnorderList>
            <Description>Don't forget, this is YOUR community as much as it is ours, so please don’t hesitate to get in touch with ideas- we’d love to hear from you!</Description>
            <Description>Contact us <BlueLink href="/contact">here</BlueLink>.</Description>
            </TextContent>
          </TextColumn2>
        </TwoColumn>
      </Container>
      <Container>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={Image5} title="Photo by Michelle Watkins" />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
            <Heading>Why We Are Doing This</Heading>
            <ImaginaryFriend>Wow, this all sounds pretty ambitious and exciting, do you mind if I ask why you’re doing all of this?</ImaginaryFriend>
            <Description>Sure! Thank you for showing so much interest; you’ve really committed to reading to get to this point.</Description>
            <Description>We all know that the pandemic was tough on everyone (and continues to be tough on the most vulnerable people in our society). But if one really great thing came out of it, it was online comedy. Comedians were left with no choice but to entertain us from their kitchens and we gratefully lapped it up from ours. They made a hard time bearable for many, and no one more so than Mark Watson and his team at Impatient Productions (no, if you’re wondering, he didn’t write this bit!).</Description>
            <Description>Mark performed about a billion online shows (a rough estimate), including three 24 hour shows, during the various periods of lockdown, raising a substantial amount of money for a range of charities.</Description>
            <Description>What Mark, and many other brilliant online comedy providers, also did was to bring a lot of people together, including many people who may not normally be able to attend in-person shows. And these providers didn't stop when lockdown ended, they are still providing top tier content to this day.</Description>
            <Description>Online comedy shows remain SO important for people with chronic illnesses, disabilities or any other impairment/vulnerability which means they cannot leave the house as much as they might want to.</Description>
            <Description>They are also a great option for people who find a 'real life' venue way too stressful or over-stimulating, such as neurodivergent people or those with anxiety. Basically, <ItalicSpan>anyone</ItalicSpan> who doesn’t feel comfortable or safe in crowded places can use online shows to enjoy live entertainment from their own sofa!</Description>
            <Description>Online shows also extend the comedian’s reach to international audiences and to people who are close to home but can’t find a babysitter or cannot afford the luxury of going out to a venue due to financial constraints or responsibilities such as caring for a relative.</Description>
            <Description>These are not just examples, these are <ItalicSpan>real</ItalicSpan> people who make up a HUGE and very valuable percentage of the population and deserve to be included, and catered for, in the post-pandemic comedy world.</Description>
            <Description><BoldSpan>We want easily accessible comedy to be a given; not a luxury, not an afterthought, not an inconvenience.</BoldSpan></Description>
            <Description><BoldSpan>That is why we are choosing to provide <YellowSpan>a helping hand to access comedy</YellowSpan> so that we ALL get to enjoy it together.</BoldSpan></Description>
            </TextContent>
          </TextColumn>
        </TwoColumn>
      </Container>
    </ContainerSpecial>
   : '' }
    </div>
  );
};

export default AboutUs
