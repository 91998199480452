import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";
import { Container as ContainerBase, ContentWithVerticalPadding, Content2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/BTL_Olana_Logo.png";
import serverIllustrationImageSrc from "images/zoom_border_grey.png";

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-4 py-8 -mx-4 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hover:text-primary-300 lg:hover:border-gray-100 lg:focus:text-primary-300 lg:focus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-primary-300`;
const SecondaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-500 lg:hocus:bg-primary-600 text-gray-700 font-bold mr-2`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-500 lg:hocus:bg-primary-600 text-gray-700 font-bold`;

const Container = tw(ContainerBase)`px-4`;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const SecondaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-500 inline-block hocus:bg-primary-600 text-gray-700 font-bold mr-2`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-500 inline-block hocus:bg-primary-600 text-gray-700 font-bold`;
const Image = tw.img`w-144 ml-auto`

const TwoColPrimaryBkround  = ({
  heading = "Below The Line",
  description = "A helping hand to access comedy.",
  secondaryButtonText = "Request a Ticket",
  primaryButtonText = "Make a Donation",
  secondaryButtonUrl = "/request",
  primaryButtonUrl = "/donate",
  imageSrc = serverIllustrationImageSrc,
}) => {
  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" />
      Below The Line
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/how">How It Works</NavLink>
      <NavLink href="/values">Our Values</NavLink>
      <NavLink href="/festivals">Festivals</NavLink>
      <NavLink href="/about">About Us</NavLink>
      <NavLink href="/contact">Contact Us</NavLink>
      <SecondaryLink href="/request">Request</SecondaryLink>
      <PrimaryLink href="/donate">Donate</PrimaryLink>
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <SecondaryButton as="a" href={secondaryButtonUrl}>{secondaryButtonText}</SecondaryButton>
                <PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton>
              </TextColumn>
              <IllustrationColumn>
                <Image src={imageSrc} title="image by Cazza" />
              </IllustrationColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};

export default TwoColPrimaryBkround;
