import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import TicketRequestForm from 'components/btl/TicketRequestForm'

const Container = tw.div`relative`;
const Content = tw.div` `;
const BlueLink = tw.a`text-blue-brightest hocus:text-blue-800`
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-400 mt-4`
const Bolding = tw.span`text-center md:text-left text-sm md:text-base lg:text-lg font-bold leading-relaxed text-secondary-400 mt-4`
const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl text-gray-700 font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-0 border-b-2 py-2 text-blue-brightest focus:border-blue-500 hover:border-blue-500 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const SimpleForm =  () => {
  return (
    <div id="nav-tickets">
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Request a ticket</h2>
            <Description>If you are struggling for cash in these tough times but would love to see a comedy show, please request a ticket using this form. We do our best to provide tickets for those who cannot otherwise afford to enjoy comedy. We hope that if you receive a ticket from our scheme, you will pay forward the kindness in whatever way you can.</Description>
            <Description>Please click <BlueLink href="/how">here</BlueLink> for full details of how our anonymous pay-it-forward ticket system works.</Description>
            <Description>We are accepting requests for all online comedy shows <Bolding>for the Edinburgh Comedy Festival</Bolding> at this time.</Description>
            <TicketRequestForm />
          </div>
        </FormContainer>
      </Content>
    </Container>
    </div>
  );
};

export default SimpleForm;
