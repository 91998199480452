import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import FestivalTicketRequestForm from 'components/btl/FestivalTicketRequestForm'

const Container = tw.div`relative`;
const Content = tw.div` `;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl text-gray-700 font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-0 border-b-2 py-2 text-blue-brightest focus:border-blue-500 hover:border-blue-500 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

const SimpleForm =  () => {
  return (
    <div id="nav-festival-tickets">
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Request an Edinburgh Fringe Ticket</h2>
            <FestivalTicketRequestForm />
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
    </div>
  );
};

export default SimpleForm;
